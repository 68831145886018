import icon1 from "./images/icons/icon1.png"
import icon2 from "./images/icons/icon2.png"
import icon3 from "./images/icons/icon3.png"
import icon4 from "./images/icons/icon4.png"
import icon5 from "./images/icons/icon5.png"
import icon6 from "./images/icons/icon6.png"
import icon7 from "./images/icons/icon7.png"
import icon8 from "./images/icons/icon8.png"
import icon9 from "./images/icons/icon9.png"
import icon10 from "./images/icons/icon10.png"
import icon11 from "./images/icons/icon11.png"
import icon12 from "./images/icons/icon12.png"
import icon13 from "./images/icons/icon13.png"
import icon14 from "./images/icons/icon14.png"
import icon15 from "./images/icons/icon15.png"
import icon16 from "./images/icons/icon16.png"
import icon17 from "./images/icons/icon17.png"

const icons = {
    icon1: icon1,
    icon2: icon2,
    icon3: icon3,
    icon4: icon4,
    icon5: icon5,
    icon6: icon6,
    icon7: icon7,
    icon8: icon8,
    icon9: icon9,
    icon10: icon10,
    icon11: icon11,
    icon12: icon12,
    icon13: icon13,
    icon14: icon14,
    icon15: icon15,
    icon16: icon16,
    icon17: icon17,
}

export default icons