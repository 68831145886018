import { useEffect, useState } from "react";
import BasicModal from "../Modal/Modal";
import email from "../../assets/images/contact/email2.png";
import linkedin from "../../assets/images/contact/linkedin2.png";
import github from "../../assets/images/contact/github2.png";
import logo from "../../assets/name-logo.svg";
import { IconButton } from "@mui/material";
import { KeyboardArrowUp } from "@mui/icons-material";
import "./Footer.scss";

const Footer = () => {
  const [modalOpen, setModalOpen] = useState(false);
  const [year, setYear] = useState(new Date().getFullYear());
  const handleModalOpen = () => {
    setModalOpen(true);
  };

  useEffect(() => {
    const intervalId = setInterval(() => {
      setYear(new Date().getFullYear());
    }, 60000);
    return () => clearInterval(intervalId);
  }, []);


  const handleScrollTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  return (
    <div className="footerSection">
      <div className="footerContent">
        <div className="socialIcons">
          <div>
            <button onClick={handleModalOpen} className="mail">
              <img src={email} alt="email" />
            </button>
            <BasicModal open={modalOpen} setOpen={setModalOpen} />
          </div>
          <a
            href="https://www.linkedin.com/in/janos-nagy-62564b262/"
            target="blank"
          >
            <img src={linkedin} alt="linkedin" />
          </a>
          <a href="https://github.com/NaJaBox" target="blank">
            <img src={github} alt="github" />
          </a>
        </div>
        <div className="testimonials">
          <div>
            <p>Designed & coded by</p>
          </div>
          <div className="name">
            <span id="name">
              <img src={logo} alt="logo" id="nameLogo" />
            </span>
            &copy;2023 - {year}
          </div>
        </div>
      </div>
      <IconButton onClick={handleScrollTop} className="scroll-top">
        <KeyboardArrowUp />
      </IconButton>
    </div>
  );
};

export default Footer;
