import "swiper/css/bundle";
import "./Projects.scss";
import projects from "../../assets/projects.js";
import githubIcon from "../../assets/images/icons/github-11-16.png";
import eyeIcon from "../../assets/images/icons/eye.png";
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination, EffectCoverflow } from "swiper/modules";
// import Footer from "../Footer/Footer";

function Projects() {
  return (
    <section className="projects">
      <div className="projectsContent">
        <h2>Projects</h2>
        <div className="cards">
          <Swiper
            effect={"coverflow"}
            grabCursor={true}
            centeredSlides={true}
            slidesPerView={"auto"}
            coverflowEffect={{
              rotate: 50,
              stretch: 0,
              depth: 100,
              modifier: 1,
              slideShadows: true,
            }}
            pagination={true}
            modules={[EffectCoverflow, Pagination]}
            className="mySwiper"
          >
            {Object.keys(projects)
              .reverse()
              .map((project) => (
                <SwiperSlide>
                  <div className="card" key={project}>
                    <div className="header">
                      <div className="info">
                        <div className="title">{projects[project].title}</div>
                        <div className="date">{projects[project].date}</div>
                      </div>
                      <div className="status">
                        <img
                          src={projects[project].status.icon}
                          alt=""
                          title={projects[project].status.name}
                        />
                      </div>
                    </div>
                    <div className="projectSS">
                      <img
                        src={projects[project].picture}
                        alt={`${projects[project].title}`}
                      />
                    </div>
                    <div className="mainInfo">
                      <div className="description">
                        {projects[project].description}
                      </div>
                      <div className="stack">
                        <div className="tools">
                          <span>Tools: </span>
                          {projects[project].tools}
                        </div>
                        <div className="footerIcons">
                          <a
                            href={projects[project].url}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <img
                              src={eyeIcon}
                              alt="Open project in new window"
                            />
                          </a>
                          <a
                            href={projects[project].github}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <img
                              src={githubIcon}
                              alt="View project on GitHub"
                            />
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </SwiperSlide>
              ))}
          </Swiper>
        </div>
      </div>
      {/* <Footer/> */}
    </section>
  );
}

export default Projects;
