import React, { useState } from "react";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import emailjs from "@emailjs/browser";
import { styled } from "@mui/material/styles";
import "./ContactForm.css";

const CustomTextField = styled(TextField)({
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      borderColor: "#a2cef5", // Change the border color
    },
    "&:hover fieldset": {
      borderColor: "#84bff3", // Change the border color on hover
    },
  },
  "& .MuiInputLabel-root": {
    color: "#8293fe", // Change the label color
  },
});

const CustomButton = styled(Button)({
  //   backgroundColor: "blue",
  color: "white",
  border: "2px solid #a2cef0",
  "&:hover": {
    border: "2px solid #a2cef3",
    backgroundColor: "#84bff3",
    color: "black",
  },
});

const ContactForm = ({ handleClose }) => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log("Name:", name);
    console.log("Email:", email);
    console.log("Message:", message);
    handleClose();

    // send form data using emailjs
    const serviceId = "service_sopqg2n";
    const templateId = "template_4jngdrj";
    const userId = "d6R2hqfqy2D1a2Q3v";

    emailjs
      .send(
        serviceId,
        templateId,
        {
          from_name: name,
          from_email: email,
          message: message,
        },
        userId
      )
      .then(
        (result) => {
          console.log(result.text);
          alert("Your message was sent successfully!");
        },
        (error) => {
          console.log(error.text);
          alert("There was an error sending your message. Please try again.");
        }
      );

    // clear form fields
    setName("");
    setEmail("");
    setMessage("");
  };

  return (
    <form onSubmit={handleSubmit}>
      <CustomTextField
        id="name"
        label="Name"
        variant="outlined"
        value={name}
        onChange={(e) => setName(e.target.value)}
        fullWidth
        margin="normal"
        required
      />
      <CustomTextField
        id="email"
        label="Email"
        variant="outlined"
        value={email}
        onChange={(e) => setEmail(e.target.value)}
        fullWidth
        margin="normal"
        required
      />
      <CustomTextField
        id="message"
        label="Message"
        variant="outlined"
        multiline
        rows={4}
        value={message}
        onChange={(e) => setMessage(e.target.value)}
        fullWidth
        margin="normal"
        required
      />
      <CustomButton type="submit" variant="outlined" fullWidth>
        Send
      </CustomButton>
    </form>
  );
};

export default ContactForm;
