import "./Hero.scss";
import { useEffect, useState } from "react";
import icons from "../../assets/icons.js";
import email from "../../assets/images/contact/email2.png";
import linkedin from "../../assets/images/contact/linkedin2.png";
import github from "../../assets/images/contact/github2.png";
import BasicModal from "../Modal/Modal";

const Hero = () => {
  function parallax(e) {
    this.querySelectorAll(".icon").forEach((icon) => {
      const speed = icon.getAttribute("data-speed");
      const x = (window.innerWidth - e.pageX * speed) / 100;
      const y = (window.innerHeight - e.pageY * speed) / 100;
      icon.style.transform = `translateX(${x}px) translateY(${y}px)`;
    });
  }

  useEffect(() => {
    const speeds = Array.from(
      { length: Object.keys(icons).length },
      () => Math.floor(Math.random() * 21) - 10
    );
    const iconElements = document.querySelectorAll(".icon");
    iconElements.forEach((icon, index) => {
      icon.setAttribute("data-speed", speeds[index]);
    });
    document.addEventListener("mousemove", parallax);
    return () => {
      document.removeEventListener("mousemove", parallax);
    };
  }, []);

  const [modalOpen, setModalOpen] = useState(false);

  const handleModalOpen = () => {
    setModalOpen(true);
  };

  return (
    <section>
      <div className="hero-main">
        <div className="hero-content">
          <div>
            <h1>Nagy Janos</h1>
          <div className="title">
            <h2>Full-Stack Web-Developer</h2>
            <div className="skills">
              <p>MongoDB</p>
              <p>Express</p>
              <p>React</p>
              <p>Node</p>
            </div>
          </div>
          <div className="contact">
            <div>
              <h2>Contact me: </h2>
            </div>
            <div className="contact-icons">
              <div>
                <button onClick={handleModalOpen} className="mail">
                  <img src={email} alt="email" />
                </button>
                <BasicModal open={modalOpen} setOpen={setModalOpen} />
              </div>
              <a
                href="https://www.linkedin.com/in/janos-nagy-62564b262/"
                target="blank"
              >
                <img src={linkedin} alt="linkedin" />
              </a>
              <a href="https://github.com/NaJaBox" target="blank">
                <img src={github} alt="github" />
              </a>
            </div>
          </div>
          </div>
          
        </div>
        <div className="iconContainer">
          <div className="icons">
            {Object.keys(icons).map((key) => (
            <img key={key} src={icons[key]} alt="" className="icon" />
          ))}
          </div>
        </div>
      </div>
    </section>
  );
};

export default Hero;
