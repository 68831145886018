import "./IntroSection.scss";
import note1 from "../../assets/images/notes/mn17.png";
import note2 from "../../assets/images/notes/mn10.png";
import note3 from "../../assets/images/notes/mn13.png";
import note4 from "../../assets/images/notes/mn18.png";
import note5 from "../../assets/images/notes/mn2.png";
import { useEffect } from "react";


function IntroSection() {
  useEffect(() => {
    const path = document.getElementById("violin");
    const pathLength = path.getTotalLength();
    path.style.strokeDasharray = pathLength;
    path.style.strokeDashoffset = pathLength;

    const drawWhenScroll = () => {
      const draw = pathLength * calcScrollPercent();
      path.style.strokeDashoffset = pathLength - draw;
    };

    const calcScrollPercent = () => {
      let height =
        document.documentElement.scrollHeight -
        document.documentElement.clientHeight;
      return document.documentElement.scrollTop / height * 1.07;
    };

    window.addEventListener("scroll", drawWhenScroll);

    return () => {
      window.removeEventListener("scroll", drawWhenScroll);
    };
  }, []);

  return (
    <section id="intro">
      <div className="sectionTextLeft">
          <p className="firstLine">
          <span className="key">@keyframes</span>
          <span className="name"> Nagy Janos</span>
          <span className="openBracket1">{" {"}</span>
        </p>
        <p className="secondLine">
          from<span className="openBracket2">{" {"}</span>
        </p>
        <p className="thirdLine">
          build: <span>musical instruments</span>
        </p>
        <span className="closeBracket">{"}"}</span>
      </div>
      <div className="violinIMG"></div>
      <div className="musicNotes">
        <div className="note1">
          <img src={note1} alt="" />
        </div>
        <div className="note2">
          <img src={note2} alt="" />
        </div>
        <div className="note3">
          <img src={note3} alt="" />
        </div>
        <div className="note4">
          <img src={note4} alt="" />
        </div>
        <div className="note5">
          <img src={note5} alt="" />
        </div>
      </div>
      <div className="animationContent">
        <div className="violinSVG">
          <svg
            viewBox="0 0 90 1017"
            fill="none"
            preserveAspectRatio="xMidYMax meet"
          >
            <path
              id="violin"
              stroke="white"
              strokeWidth={3}
              d="M47.5 0.5V236H42C42 236 38 236 37.5 241V246H35.5C35.5 246 35.5 241.5 31 241.5C31 241.5 26.5 241.5 26 246C26 246 26 249.5 30.5 250.5C30.5 250.5 34.5 250.5 35.5 246.5H37.5V259H35.5C35.5 259 35.5 255 31 254.5C31 254.5 27 254 26 259C26 259 26 262.5 30.5 263.5C30.5 263.5 34.5 263.5 35.5 260H37.5V263.5C37.5 263.5 38.5 267 42 267.5L39.5 302.5C39.5 302.5 29.5 302.5 20 312.5C20 312.5 9.99998 321 12.5 338C12.5 338 13 344.5 16.5 349.5L17 351.5C17 351.5 17 355.5 20 353C20 353 23 351 25.5 357C25.5 357 28 362.5 25.5 370.5C25.5 370.5 24 376.5 17.5 376.5L13 377L12 379L13.5 382V383.5C13.5 383.5 8.49999 387 8.49999 401.5C8.49999 401.5 7.99999 416 16.5 423.5C16.5 423.5 25.5 433 39.5 434.5L40 439L46 439.5V495.75V552V558.5V618V632.875V647.75V677.5L36 679L19.5 687L36 694.5L43.5 692L50 670L42 700.5L49.5 674.5L56 679L72 687L56 694.5L46 716V1016.5"
            />
          </svg>
        </div>
        <div className="circlesLeft">
          <div className="smallCircleLeft">
            <svg
              id="smallCircleLeft"
              width="116"
              height="233"
              viewBox="0 0 116 233"
              fill="none"
            >
              <path
                d="M0 225C59.9229 225 108.5 176.423 108.5 116.5C108.5 56.5771 59.9229 8 0 8"
                stroke="#f5f5f5"
                strokeWidth="5"
              />
            </svg>
          </div>
          <div className="bigCircleLeft">
            <svg
              id="bigCircleLeft"
              width="180"
              height="361"
              viewBox="0 0 180 361"
              fill="none"
            >
              <path
                d="M0 353C95.2691 353 172.5 275.769 172.5 180.5C172.5 85.2309 95.2691 8 0 8"
                stroke="#f5f5f5"
                strokeWidth="5"
              />
            </svg>
          </div>
        </div>
        <div className="sectionTextRight">
          <div className="keyboard"></div>
          <div className="contentTextRight">
            <p className="firstLineRight">
              to<span className="openBracket2">{" {"}</span>
            </p>
            <p className="secondLineRight">
              build: <span>things for the web</span>
            </p>
            <span className="closeBracketRight">{"}"}</span>
            <span className="secondLineBracket">{"}"}</span>
          </div>
        </div>
        <div className="circlesRight">
          <div className="smallCircleRight">
            <svg
              id="smallCircleRight"
              width="117"
              height="233"
              viewBox="0 0 117 233"
              fill="none"
            >
              <path
                d="M116.906 7.78467C56.983 7.78467 8.40585 56.3618 8.40585 116.285C8.40585 176.208 56.983 224.785 116.906 224.785"
                stroke="#f5f5f5"
                strokeWidth="5"
              />
            </svg>
          </div>
          <div className="bigCircleRight">
            <svg
              id="bigCircleRight"
              width="181"
              height="361"
              viewBox="0 0 181 361"
              fill="none"
            >
              <path
                d="M180.5 8C85.2309 8 8 85.2309 8 180.5C8 275.769 85.2309 353 180.5 353"
                stroke="#f5f5f5"
                strokeWidth="5"
              />
            </svg>
          </div>
        </div>
      </div>
    </section>
  );
}

export default IntroSection;
