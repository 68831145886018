import "./App.scss";
import NavBar from  "./components/NavBar/NavBar"
import Hero from "./components/Hero/Hero.jsx";
import IntroSection from "./components/IntroSection/IntroSection";
import Projects from "./components/Projects/Projects";
import Footer from "./components/Footer/Footer";

function App() {
  return (
    <div id="app-container" className="App">
      <NavBar/>
      <Hero />
      <IntroSection />
      <Projects />
      <Footer/>
    </div>
  );
}


export default App;
