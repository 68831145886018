import finished from "./images/finished.png";
import wip from "./images/wip.png";
import portfolio2 from "./images/portfolio-v2.PNG";
import weather from "./images/weather.PNG";
import speedboat from "./images/speedboat.PNG";
import portfolio1 from "./images/portfolio-v1.PNG";
import acme from "./images/acme.PNG"

const projects = {
  project1: {
    title: "Portfolio v1",
    date: "August 2022",
    status: {
      icon: finished,
      name: "Concluded",
    },
    picture: portfolio1,
    description:
      "My first portfolio; class assignment after completing the UI Basics module at DCI.",
    tools: "HTML, SCSS",
    url: "https://njk-portfolio-v1.netlify.app/",
    github: "https://github.com/NaJaBox/portfolio-1.0",
  },
  project2: {
    title: "Weather App",
    date: "January 2023",
    status: {
      icon: wip,
      name: "Ongoing Development",
    },
    picture: weather,
    description:
      "A group project, after we finished the FrontEnd module at DCI",
    tools: "SCSS, JavaScript, React",
    url: "https://njk-weather-app.netlify.app/",
    github: "https://github.com/NaJaBox/weather-app",
  },

  project3: {
    title: "Speed Boat",
    date: "February 2023",
    status: {
      icon: wip,
      name: "Ongoing Development",
    },
    picture: speedboat,
    description:
      "I added an animated interface to a simple class assignment (about react reduce). More details in the Readme.md on GitHub.",
    tools: "SCSS, JavaScript, React",
    url: "https://speed-boat.netlify.app/",
    github: "https://github.com/NaJaBox/speed-boat",
  },

  project4: {
    title: "Portfolio v2",
    date: "March 2023",
    status: {
      icon: wip,
      name: "Ongoing Development",
    },
    picture: portfolio2,
    description:
      "A refreshed portfolio page, with a simpler layout but with more animation.",
    tools: "SCSS, JavaScript, React",
    url: "",
    github: "https://github.com/NaJaBox/portfolio-v2",
  },

  project5: {
    title: "ACME-Gruppe",
    date: "October 2023",
    status: {
      icon: wip,
      name: "Ongoing Development",
    },
    picture: acme,
    description:
      "A recruiting company landing page with admin dashboard for an easy candidate selection.",
    tools: "Next.js, TS, Kinde",
    url: "https://acme-gruppe.netlify.app/",
    github: "",
  },
};

export default projects;
